<template>
  <v-app id="site">
    <v-app-bar
      app
      :clipped-left="false"
      color="primary"
      :dark="true"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-text="title"></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
      mobile-breakpoint="1024"
      :mini-variant="miniVariant"
      :clipped="false"
      v-model="drawer"
      :fixed="true"
      app
      width="250"
    >
    <v-list>
      <v-list-item color="primary" :to="{name:'Introduction'}">
          <v-list-item-action>
            <v-icon color="primary">mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Introduction</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-list-item color="primary" :to="{name:'GeneralInfo'}">
          <v-list-item-action>
            <v-icon color="primary">mdi-information</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>General Info</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-list-item color="primary" :to="{name:'Booklet'}">
          <v-list-item-action>
            <v-icon color="primary">mdi-book-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Booklet</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <v-list-group no-action sub-group>
        <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Lessons</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="item in items" :key="item.title" :to="{path:'/lessons/' + item.title,params:{source:item.path}}" color="primary">
              <v-list-item-title v-html="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
      <div v-for="subItem in mainMenus" :key="subItem.title">
        <v-list-item color="primary" :to="{name:subItem.title}">
          <v-list-item-action>
            <v-icon color="primary">{{ subItem.icon }}</v-icon>
          </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="subItem.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
      </div>
      </v-list>
    </v-navigation-drawer>
    
    <v-main fluid>
      <v-fade-transition mode="out-in">
      <router-view/>
      </v-fade-transition>
      <p></p>
      <p></p>
      <v-footer>
        <v-card flat tile class="flex" color="primary" :dark="true">
            <v-card-title><span>&copy; {{year}} Church in Irvine. All rights reserved.</span></v-card-title>
        </v-card>
      </v-footer>
    </v-main>
    
  </v-app>
</template>

<script>
// import Vue from 'vue'
// import VueSupabase from 'vue-supabase';
// Vue.use(VueSupabase, {
//   supabaseUrl: "https://pbzlpynpzeyokddqdbhy.supabase.co",
//   supabaseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBiemxweW5wemV5b2tkZHFkYmh5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTk4ODU3MzQsImV4cCI6MTk3NTQ2MTczNH0.bFAMKDBUV3FHOMzoWj85PdRqs5TDmxZIzwuLuuCaQ3A",
//   supabaseOptions: {},
// });
// import { createClient } from '@supabase/supabase-js'
// const _supabase = createClient('https://pbzlpynpzeyokddqdbhy.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBiemxweW5wemV5b2tkZHFkYmh5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTk4ODU3MzQsImV4cCI6MTk3NTQ2MTczNH0.bFAMKDBUV3FHOMzoWj85PdRqs5TDmxZIzwuLuuCaQ3A')
// console.log('supabase:', _supabase)
export default {
  async mounted() {
    console.log('vuetify')
    // const stories =  await _supabase.from('stories').select('*')
    
    // console.log('stories:',stories.data)

    // const lessons = await _supabase.from('lessons').select('*')
    // console.log('lessons:',lessons.data)
  },
  data() {
    return {
      year: new Date().getFullYear(),
      clipped: false,
      drawer: document.body.offsetWidth >= 1024,
      fixed: false,
      mainMenus: [
        {
          icon: "mdi-post-outline",
          title: "Stories"
        },
        {
          icon: "mdi-piano",
          title: "Songs"
        },
        {
          icon: "mdi-pinwheel-outline",
          title: "Crafts"
        },
        {
          icon: "mdi-presentation",
          title: "Prezi"
        }
      ],
      items: [
        { title: "The Ten Commandments", path: "/data/lessons-ten-commandments.json" },
        { title: "God's Creation", path: "/data/lessons-gods-creation.json" },
        { title: "Character", path: "/data/lessons-character.json" },
        // { title: "General Info", path: "/data/lessons-general-info.json" },
        { title: "God is", path: "/data/lessons-god-is.json" },
        { title: "Proper Humanity", path: "/data/lessons-proper-humanity.json" }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Church in Irvine Children's Service"
    };
  },
  name: "App"
};
</script>
<style>
#site .content .list__tile {
  height: auto;
  margin: 4px 0;
  padding: 2px;
}
#site .content .list__tile__avatar {
  min-width: 0;
  margin-top: 0;
}
#site .content .card__title {
  padding: 4px 16px;
}
#site .content .card__actions {
  padding: 2px 16px;
}
</style>
