<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout row align-center>
          <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
          <h1 class="headline blue--text">Lesson: {{ this.$route.params.type }}</h1>
          <v-text-field
            name="search"
            label="Search"
            v-model="filter"
            :hide-details="true"
          ></v-text-field>
          <v-list three-line :expand="true">
              <template v-for="(item, index) in items">
                  <v-divider v-if="index > 0" :key="index"></v-divider>
                    <v-list-item  @click="gotoLink('/' + item.link)">                   
                        <v-list-item-avatar>
                                <v-icon color="primary">{{ fileIcon(item.title) }}</v-icon>
                            </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="item.lessonDate"></v-list-item-title>
                            <v-list-item-subtitle v-html="item.title"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
              </template>
          </v-list>
          </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
// import R, {filter, find, propEq} from 'ramda'
import * as R from 'ramda'
import store, { computedState } from '../stores/supabaseStore'

export default {
    props: {
        source: String
    },
    computed: {
        ...computedState,
        // sourceItems() {
        //     console.log('items computed:', store.getLessons())
        //     return store.getLessons()
        // }
    },
    methods: {
        fileIcon: (file) => {
            file = file.toLowerCase()
            return file.endsWith('.pdf') ? 
                'mdi-file': file.endsWith('.mp3') 
                || file.endsWith('.m4a') 
                || file.endsWith('.3gp') ? 'audiotrack':''
        },
        gotoLink: function(link){
            if (window.ga) {
                ga('send', 'pageview', link)
            }
            window.open(link)
        },
        async fetchData () {
                // console.log('fetch data:', this.$route.params.type)
            let title = this.$route.params.type
            let name = R.find(R.propEq('title',title))(this.paths).name
            console.log('fetch data:', title, name)
            this.sourceItems = await store.getLessons().filter(lesson => lesson.category === name)
            this.items = this.sourceItems
            // fetch(path)
            //     .then(response => response.json())
            //     .then(data => {
            //         this.sourceItems = this.items = data.files                   
            //     })
            //     .catch(error =>console.log(error))
            // await store.retrieveLessons()
            // this.sourceItems = store.getLessons()
            // this.sourceItems = store.lessons
            // console.log('items:', this.sourceItems)
        }
    },
  data() {
    return {
        loading: false,
        paths: [
            { title: "The Ten Commandments", path: "/data/lessons-ten-commandments.json", name: "Commandments" },
            { title: "God's Creation", path: "/data/lessons-gods-creation.json", name: "Creation" },
            { title: "Character", path: "/data/lessons-character.json", name: "Character" },
            // { title: "General Info", path: "/data/lessons-general-info.json" },
            { title: "God is", path: "/data/lessons-god-is.json", name: "GodIs" },
            { title: "Proper Humanity", path: "/data/lessons-proper-humanity.json", name: "Humanity" }
        ],
        sourceItems: [],
        items: [],
        filter: ''
    }
  },
  async created () {
    console.log('created')
    // await this.fetchData()
    await store.retrieveLessons()
    await this.fetchData()
    await console.log('data:', this.sourceItems)
    this.items = await [...this.sourceItems]
    //   store.retrieveLessons()
  },
  watch: {
        '$route.params.type'(newId, oldId) {
            this.fetchData()
        },
        'filter'(newId, oldId) {
            console.log('filter:', newId)
            this.items = R.filter((key, val) => key.title.toLowerCase().indexOf(newId.toLowerCase()) > -1)(this.sourceItems)
        }
    }
}
</script>