<template>
  <v-container fluid grid-list-md style="min-height: 0;">
    <v-slide-y-transition mode="out-in">
      <v-layout id="storyContainer" row wrap>
          <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
          <h1 class="headline blue--text">Stories</h1>
          <h2>Guidelines for Story Time</h2>
          <ol>
            <li>We use Bible stories to show the children the proper standard of human life and who God is.</li>
            <li>To prepare, prayerfully read the assigned Scripture portion for your week and select some appropriate portions of the Scripture to tell the children.</li>
            <li>Do not try to cover everything in the Scripture references. Instead, select 1 or 2 points about who God is or what is proper humanity to impress the children.</li>
            <li>Do not cover anything which is beyond their age to understand.</li>
            <li>You can verbally tell (paraphrase) the stories rather than just reading the verses.</li>
            <li>Points to apply may be included below for your week’s Scripture portion. You are not limited to using the suggested points provided.</li>
          </ol>
          <v-text-field
            name="search"
            label="Search"
            v-model="filter"
            :hide-details="true"
          ></v-text-field>
          </v-flex>
          <!-- <v-list three-line :expand="true"> -->
              <template v-for="(item, index) in items">
                  <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
                  <v-card :color="index == latestIndex?'accent':''" :class="['elevation-6']">
                <v-card-title primary-title>
                    <div>
                  <h3 class="title" v-html="item.subject" :id="'story_' + item.lessonDate.replace(/\//g, '_')"></h3>
                  <div class="subheading">{{item.reference}}</div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <div class="body-1" v-html="item.lessonDate"></div>
                </v-card-actions>
              </v-card>
                  </v-flex>
                <!-- <v-divider v-if="index > 0" :key="index"></v-divider>
                <v-list-tile  avatar @click="gotoLink('/' + item.link)">  
                    <v-list-tile-content>
                        <v-list-tile-title v-html="item.lastModified"></v-list-tile-title>
                        <v-list-tile-sub-title v-html="item.subject"></v-list-tile-sub-title>
                        <v-list-tile-sub-title v-html="item.reference"></v-list-tile-sub-title>               
                    </v-list-tile-content>
                </v-list-tile> -->
              </template>
          <!-- </v-list> -->         
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import Vue from 'vue'
// import R, {findIndex, filter} from 'ramda'
import * as R from 'ramda'
import dayjs from 'dayjs'
var vueScrollTo = require('vue-scrollTo')
Vue.use(vueScrollTo)
import store, { computedState } from '../stores/supabaseStore'

export default {
    methods: {
        gotoLink: function(link){
            ga('send', 'pageview', link)
            window.open(link)
        },
        fetchData () {
            // fetch('/data/stories.json')
            //     .then(response => response.json())
            //     .then(data => {
            //         this.sourceItems = this.items = data.files                   
            //     })
            //     .catch(error =>console.log(error))
            this.sourceItems = store.getStories()
        }
    },
  data() {
    return {
        loading: false,
        sourceItems: [],
        items: [],
        filter: '',
        latestIndex: 0
    }
  },
  async created () {
      await store.retrieveStories()
      await this.fetchData()
      this.items = await [...this.sourceItems]
  },
  watch: {
    'sourceItems'() {
        this.latestIndex = R.findIndex(a => {
            // console.log('compare ', moment().toString(), moment(a.lastModified).toString())
            // return moment().isBefore(moment(a.lastModified))
            return dayjs().isBefore(dayjs(a.lessonDate))
        })(this.sourceItems)
        Vue.nextTick(() => {
          console.log('last Index:', this.latestIndex)
        if (this.latestIndex > 0 && this.sourceItems.length > 0) {
        let name = '#story_' + this.sourceItems[this.latestIndex].lessonDate.replace(/\//g, '_')
        // console.log('scroll to:', name, document.querySelector(name))
        vueScrollTo.scrollTo(name, 1000, {
            // container: '#storyContainer',
            easing: 'ease',
            offset: -100,
            // force: true,
            // cancelable: true,
            // x: false,
            // y: true
        })
        // this.$scrollTo(document.querySelector(name), 1000, {offset: -100})
        }
        })
        
    },
    'filter'(newId, oldId) {
        this.items = R.filter((key, val) => key.subject.toLowerCase().indexOf(newId.toLowerCase()) > -1 
            || key.reference.toLowerCase().indexOf(newId.toLowerCase()) > -1)(this.sourceItems)
    }
  }
}
</script>