import Vue from 'vue'
import Router from 'vue-router'
import Introduction from '@/components/Introduction'
import Lessons from '@/components/Lessons'
import Stories from '@/components/Stories'
import Songs from '@/components/Songs'
import Crafts from '@/components/Crafts'
import GeneralInfo from '@/components/GeneralInfo'
import Booklet from '@/components/Booklet'
import Prezi from '@/components/Prezi'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Introduction',
      component: Introduction
    },
    {
        path: '/generalInfo',
        name: 'GeneralInfo',
        component: GeneralInfo
    },
    {
        path: '/booklet',
        name: 'Booklet',
        component: Booklet
    },
    {
      path: '/lessons/:type',
      name: 'Lessons',
      component: Lessons,
      props: (route) => ({source: route.params.source}),
    },
    {
      path: '/stories',
      name: 'Stories',
      component: Stories
    },
    {
      path: '/songs',
      name: 'Songs',
      component: Songs
    },
    {
      path: '/crafts',
      name: 'Crafts',
      component: Crafts
    },
    {
      path: '/prezi',
      name: 'Prezi',
      component: Prezi
    }
  ]
})
