import Vue from 'vue'
import { createClient } from '@supabase/supabase-js'
const _supabase = createClient('https://pbzlpynpzeyokddqdbhy.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBiemxweW5wemV5b2tkZHFkYmh5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTk4ODU3MzQsImV4cCI6MTk3NTQ2MTczNH0.bFAMKDBUV3FHOMzoWj85PdRqs5TDmxZIzwuLuuCaQ3A')
// console.log('supabase:', _supabase)

export const state = Vue.observable({
    lessons: [],
    stories: [],
    crafts: [],
    songs: []
})

export const computedState = {
    categoryLessons: {
        get: c => state.lessons.filter(lesson => lesson.category == c)
    }
}

export default {
    async retrieveLessons(category) {
        const lessons = await _supabase.from('lessons').select('*')//.eq('category',category)
        this.setLessons(lessons.body)
    },

    getLessons() {
        return state.lessons
    },

    async setLessons(data) {
        state.lessons = data
    },

    async retrieveStories() {
        const stories = await _supabase.from('stories').select('*')
        this.setStories(stories.body)
    },

    getStories() {
        return state.stories
    },

    async setStories(data) {
        state.stories = data
    },

    async retrieveCrafts() {
        const crafts = await _supabase.from('crafts').select('*')
        this.setCrafts(crafts.body)
    },

    getCrafts() {
        return state.crafts
    },

    async setCrafts(data) {
        state.crafts = data
    },

    async retrieveSongs() {
        const songs = await _supabase.from('songs').select('*')
        this.setSongs(songs.body)
    },

    getSongs() {
        return state.songs
    },

    async setSongs(data) {
        state.songs = data
    }
}