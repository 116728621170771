import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify,{
  theme: {
    primary: colors.blue.darken3,
    secondary: colors.cyan.darken1,
    accent: colors.deepOrange.lighten2,
    error: colors.orange.darken3,
    warning: "#FFCA28",
    info: "#00B0FF",
    success: "#4caf50"
  }
})

Vue.config.productionTip = false

new Vue({
  // el: '#app',
  // Vuetify,
  vuetify,
  router,
  // components: { App },
  render: h => h(App)
  // template: '<App/>'
})
.$mount('#app')
