<template>
  <v-container fluid grid-list-md style="min-height: 0;">
    <v-slide-y-transition mode="out-in">
      <v-layout id="storyContainer" row wrap>
          <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
          <h1 class="headline blue--text">General Information</h1>
        
          </v-flex>
          <!-- <v-list three-line :expand="true"> -->
              <template v-for="(item, index) in items">
                  <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
                  <v-card :class="['elevation-2']">
                <v-card-title primary-title @click="gotoLink('/' + item.link)"">
                    <div>
                  <h3 class="title" v-html="item.title" :id="'story_' + item.lastModified.replace(/\//g, '_')"></h3>
                  <div class="subheading">{{item.reference}}</div>
                  </div>
                </v-card-title>
              </v-card>
                  </v-flex>
                <!-- <v-divider v-if="index > 0" :key="index"></v-divider>
                <v-list-tile  avatar @click="gotoLink('/' + item.link)">  
                    <v-list-tile-content>
                        <v-list-tile-title v-html="item.lastModified"></v-list-tile-title>
                        <v-list-tile-sub-title v-html="item.subject"></v-list-tile-sub-title>
                        <v-list-tile-sub-title v-html="item.reference"></v-list-tile-sub-title>               
                    </v-list-tile-content>
                </v-list-tile> -->
              </template>
          <!-- </v-list> -->         
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>
<script>
import Vue from 'vue'

export default {
    methods: {
        gotoLink: function(link){
            ga('send', 'pageview', link)
            window.open(link)
        },
        fetchData () {
            fetch('/data/lessons-general-info.json')
                .then(response => response.json())
                .then(data => {
                    this.sourceItems = this.items = data.files                   
                })
                .catch(error =>console.log(error))
        }
    },
  data() {
    return {
        loading: false,
        sourceItems: [],
        items: [],
        filter: '',
        latestIndex: 0
    }
  },
  created () {
      this.fetchData()
  },
}
</script>