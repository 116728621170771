<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout row align-center>
          <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
            <h1 class="headline blue--text">Crafts</h1>
          <v-text-field
            name="search"
            label="Search"
            v-model="filter"
            :hide-details="true"
          ></v-text-field>
          <v-list two-line :expand="true">
              <template v-for="(item, index) in items">
                  <v-divider v-if="index > 0" :key="index"></v-divider>
                    <v-list-item @click="gotoLink('/' + item.link)">                   
                        <v-icon large color="primary">mdi-file</v-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
              </template>
          </v-list>
          </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
// import R, {filter} from 'ramda'
import * as R from 'ramda'
import store, { computedState } from '../stores/supabaseStore'

export default {
    methods: {
        fileIcon: (file) => {
            file = file.toLowerCase()
            return file.endsWith('.pdf') ? 'picture_as_pdf': file.endsWith('.mp3') || file.endsWith('.m4a') || file.endsWith('.3gp')? 'audiotrack':''
        },
        gotoLink: function(link){
          if (window.ga) {
                ga('send', 'pageview', link)
            }
            window.open(link)
        },
        fetchData () {
            this.sourceItems = store.getCrafts()
        }
    },
  data() {
    return {
        loading: false,
        sourceItems: [],
        items: [],
        filter: ''
    }
  },
  async created () {
      await store.retrieveCrafts()
      await this.fetchData()
      this.items = await [...this.sourceItems]
  },
  watch: {
    'filter'(newId, oldId) {
        this.items = R.filter((key, val) => key.title.toLowerCase().indexOf(newId.toLowerCase()) > -1)(this.sourceItems)
    }
  }
}
</script>