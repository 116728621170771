<template>
  <v-container fluid grid-list-md style="min-height: 0;">
    <v-slide-y-transition mode="out-in">
      <v-layout id="preziContainer" row wrap>
        <v-flex xs12 sm10 md10 offset-md1 offset-sm1>
          <h1 class="headline blue--text">Prezi</h1>
          <v-list two-line :expand="true">
            <template v-for="(item, index) in items">
              <!-- <v-flex xs12 sm10 md10 offset-md1 offset-sm1> -->
              <v-card :color="index == latestIndex?'accent':''" :class="['elevation-6']">              
              <v-card-title>
                <h3 class="title" v-html="item.lastModified"></h3>
              </v-card-title>
              <v-list-tile avatar v-if="item.Elementary" @click="gotoLink(item.Elementary)">
                <v-list-tile-content :class="{ 'on-hover': 'blue--text text--darken-2' }">
                  <v-list-tile-title>Elementary</v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.Elementary"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar v-if="item.Intermediate" @click="gotoLink(item.Intermediate)">
                <v-list-tile-content>
                  <v-list-tile-title>Intermediate</v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.Intermediate"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar v-if="item.Advanced" @click="gotoLink(item.Advanced)">
                <v-list-tile-content>
                  <v-list-tile-title>Advanced</v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.Advanced"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              </v-card>
              <!-- </v-flex> -->
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import Vue from "vue";
// import R, { findIndex, filter } from "ramda";
import * as R from 'ramda'
import dayjs from "dayjs";
var vueScrollTo = require("vue-scrollTo");
Vue.use(vueScrollTo);

export default {
  methods: {
    gotoLink: function (link) {
      ga("send", "pageview", link);
      window.open(link);
    },
    fetchData() {
      fetch("/data/prezi.json")
        .then((response) => response.json())
        .then((data) => {
          this.sourceItems = this.items = data.files;
        })
        .catch((error) => console.log(error));
    },
  },
  data() {
    return {
      loading: false,
      sourceItems: [],
      items: [],
      filter: "",
      latestIndex: 0,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    sourceItems() {
      this.latestIndex = R.findIndex((a) => {
        // console.log('compare ', moment().toString(), moment(a.lastModified).toString())
        // return moment().isBefore(moment(a.lastModified))
        return dayjs().isBefore(dayjs(a.lastModified));
      })(this.sourceItems);
      Vue.nextTick(() => {
        if (this.latestIndex > 0 && this.sourceItems.length > 0) {
          let name =
            "#story_" +
            this.sourceItems[this.latestIndex].lastModified.replace(/\//g, "_");
          // console.log('scroll to:', document.querySelector(name))
          vueScrollTo.scrollTo(document.querySelector(name), 1000, {
            // container: '#storyContainer',
            // easing: 'ease-in',
            offset: -100,
            // force: true,
            // cancelable: true,
            // x: false,
            // y: true
          });
        }
      });
    },
    filter(newId, oldId) {
      this.items = R.filter(
        (key, val) =>
          key.subject.toLowerCase().indexOf(newId.toLowerCase()) > -1 ||
          key.reference.toLowerCase().indexOf(newId.toLowerCase()) > -1
      )(this.sourceItems);
    },
  },
};
</script>